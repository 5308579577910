import React, { Component } from 'react';
import Footer from './Footer';
import {Link} from 'react-router-dom';
import Cookies from 'universal-cookie';
import ReactPixel from 'react-facebook-pixel';

import {Fade} from 'react-slideshow-image';

const cookies = new Cookies();

//Facebook Pixel

const options = {
	autoConfig: true, 	
    debug: false, 		
};
ReactPixel.init('329882740928988', options);

const fadeImages = [
    'images/VirtualFob.png',
    'images/HeaderPhone.png',
  ];
   
  const fadeProperties = {
    duration: 8000,
    transitionDuration: 300,
    infinite: true,
    indicators: true,
    arrows: false
  }
   
  const SlideShow = () => {
    return (
      <Fade {...fadeProperties}>
        <div style={{display: "flex"}}>
          <div className="img-fluid pt-10">
            <img src={fadeImages[0]} />
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div className="img-fluid">
            <img src={fadeImages[1]} />
          </div>
        </div>
      </Fade>
    )
  }

class Landing extends Component {
    constructor(){
        super();
        this.state= {
            ld: false,
            pv: false,
            suh: false,
            suc: false,
            ph: false,
            pc: false,
        }
    }

    
    componentDidMount(){
        window.scrollTo(0, 0); 
        document.title=`Buzzly - Your buzzer, smarter. | Smart cloud buzzer for multiple cell phones, roommates, landlords, and Airbnb home sharing hosts.`; 
        
        if (cookies.get("coupon")){
            sessionStorage.setItem("coup",cookies.get("coupon"));
            cookies.remove("coupon");
        }
        if (!this.state.pv){
            this.setState({pv:true},()=>{
                ReactPixel.pageView();
            })
        }
        
    }

   
    lead = () =>{
        if (!this.state.ld){
            this.setState({ld:true},()=>{
                ReactPixel.track("Lead");
            })
        }
        
    }
    suh = () =>{
        if (!this.state.suh){
            this.setState({suh:true},()=>{
                ReactPixel.trackCustom("SignUpHover");
            })
        }
        
    }
    suc = () =>{
        if (!this.state.suc){
            this.setState({suc:true},()=>{
                ReactPixel.trackCustom("SignUpClick");
            })
        }
        
    }
    ph = () =>{
        if (!this.state.ph){
            this.setState({ph:true},()=>{
                ReactPixel.trackCustom("PricingHover");
            })
        }
        
    }
    pc = () =>{
        if (!this.state.pc){
            this.setState({pc:true},()=>{
                ReactPixel.trackCustom("PricingClick");
            })
        }
        
    }

    
    
    render() {
        return (
        <body data-spy="scroll" data-offset="124" data-target="#navbarlanding" >
       
           
            <nav className="navbar navbar-expand-lg navbar-light bg-faded navbar-sticky navbar-transparent">
                <div className="container">
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarlanding" aria-controls="navbarlanding" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" id="toggler"></span>
                    </button>
                    <Link className="navbar-brand" to="/">
                        <img src="https://api.buzzly.co/logo/" alt="Buzzly" width="125" id="logo"/>
                    </Link>

                    <div className="collapse navbar-collapse" id="navbarlanding">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" data-scroll href="#home">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-scroll href="#features">Features</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-scroll href="#howitworks">How it Works</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-scroll href="#pricing" onClick={this.lead}>Pricing</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-scroll href="#faq">FAQ</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://help.buzzly.co">Support</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-warning text-nowrap" id="login-nav" href="https://cloud.buzzly.co/">Log In</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link btn btn-warning ml-0" href="https://cloud.buzzly.co/register" onMouseOver={this.suh} onClick={()=>{this.lead(); this.suc();}}><span className="text-buzzly-blue">Sign Up</span></a>
                            </li>
                        
                        </ul>
                            
                    </div>
                </div>
            </nav>
            
            <div id="home" className="hero-section bg-buzzly-blue pt90">
                <div className="container">
                    <div className="row align-items-center">                  
                        <div className="col-sm-12 col-md-7 col-lg-6 text-sm-center text-md-left offset-1 ml-auto pb50">
                            <h2 className="mb20 h1 font300 text-white">
                                Your buzzer, <b>smarter</b>.
                            </h2>
                            <div className="clearfix text-white">
                                <p>
                                    Buzzly adds smart features to the apartment or condo buzzer you already have. Get started quickly with nothing to install.
                                </p>

                                <a href="https://cloud.buzzly.co/register" className="btn btn-outline-warning mr-3 mb-2" onMouseOver={this.suh} onClick={()=>{this.lead(); this.suc();}}>Sign Up</a>
                                <a href="https://cloud.buzzly.co/console/" className="btn btn-white-outline mr-3 mb-2">Log In</a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-5 mr-auto pb40">
                            <img src="images/HeaderMulti.png" className="img-fluid" alt="Buzzly Illustration"/>
                        </div>
                    </div>
                </div>
            </div>

           

            <div className="pt90 pb60 bg-faded">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-6 ml-auto mr-auto mb60" data-wow-delay=".1s">
                            <h2 className="font300 text-center h1">Teach your apartment buzzer some new tricks.</h2>
                            <p className="lead text-center"></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 text-center mb30" data-wow-delay=".1s">
                            <i className="fas fa-map-marker-alt text-danger fa-3x mb30"></i>
                            <h3>No Local Number? No Problem.</h3>
                            <p>
                                Keep your phone number. Buzzly gives you a local phone number to use with your buzzer and will forward buzzer calls to your non-local cell phone that your building won't let you use.
                            </p>
                        </div>
                        <div className="col-lg-4 text-center mb30" data-wow-delay=".2s">
                            <i className="fas fa-user-friends text-info fa-3x mb30"></i>
                            <h3>One Buzzer For All</h3>
                            <p>
                                Buzzly will forward calls to multiple phones when someone's at your door. No more relying on your roommate to buzz in the pizza guy.
                            </p>
                        </div>
                        <div className="col-lg-4 text-center mb30" data-wow-delay=".3s">
                            <i className="fas fa-concierge-bell text-success fa-3x mb30"></i>
                            <h3>Meet Your New Doorman</h3>
                            <p>
                                Smart features put you in control. Set Buzzly to answer buzzer calls for you during a party or a home-sharing check-in window, or send a Virtual Fob to your family, friends, dog walker, or cleaning person.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="features">
                    <div className="pt90 bg-white">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-6 ml-auto mr-auto mb60" data-wow-delay=".1s">
                                    <h2 className="title-font mb5 text-center">Features</h2>
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4" data-wow-delay=".1s">
                                    
                                    <h4><i className="fas fa-map-marker-alt text-warning mr-2"></i> <b>Local Buzzly Number</b></h4>

                                    <p lassName="text-left">
                                        A new local phone number, specific to you, for you to use with your building's buzzer. No landline required.
                                    </p>
                                </div>
                                <div className="col-lg-4  mb30" data-wow-delay=".1s">
                                    
                                    <h4><i className="fas fa-phone text-warning mr-2"></i><b>Use Multiple Phones</b></h4>

                                    <p lassName="text-left">
                                        Forward buzzer calls to everyone in your home at the same time. The first person to answer is connected through to your guest. <br/><a href="#call-forwarding" data-scroll className="text-warning mt-2"><b>More Info</b></a>
                                    </p>
                                </div>
                                <div className="col-lg-4 mb30" data-wow-delay=".1s">
                                    
                                    <h4><i className="fas fa-magic text-warning mr-2"></i><b>Instant Forwarding Changes</b></h4>

                                    <p lassName="text-left">
                                        Instantly change which phones your buzzer rings on. Stop receiving calls while you're on vacation or temporarily add a guest or Airbnb renter.
                                    </p>
                                </div>
                                <div className="col-lg-4 mb30" data-wow-delay=".1s">
                                    
                                    <h4> <i className="fas fa-mobile-alt text-warning mr-2"></i><b>Virtual Fobs</b></h4>

                                    <p className="text-left">
                                    Send a Virtual Fob to someone who needs to get in when you're not around. Virtual Fobs can be scheduled to work only on certain dates and times, or at the same time(s) every week. <br/><a href="#virtual-fobs" data-scroll className="text-warning mt-2"><b>More Info</b></a>
                                    </p>
                                </div>
                                <div className="col-lg-4 mb30" data-wow-delay=".1s">
                                    
                                    <h4> <i className="fas fa-user-clock text-warning mr-2"></i><b>Scheduled Access</b></h4>

                                    <p className="text-left">
                                    Automatically answer buzzer calls and unlock the door during specified periods of time. Great for parties, deliveries, and businesses. <br/><a href="#scheduled-access" data-scroll className="text-warning mt-2"><b>More Info</b></a>
                                    </p>
                                </div>
                                <div className="col-lg-4 mb30" data-wow-delay=".1s">
                                    
                                    <h4> <i className="fas fa-cloud text-warning mr-2"></i><b>Simple, Stealthy, and Secure</b></h4>

                                    <p className="text-left">
                                    Buzzly is cloud based, has no hardware to install, and works seamlessly. To everyone else, your buzzer will appear to be working normally. Buzzes are logged in real time, letting you see which phone, Virtual Fob, or Schedule opened the door.<br/><a href="#buzz-log" data-scroll className="text-warning mt-2"><b>More Info</b></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            
            
                <div className="container pt90 pb60" id="call-forwarding">
                    <div className="row align-items-center">
                        <div className="col-lg-5 ml-auto mb30" data-wow-delay=".1s">
                            <h2 className="title-font">
                                <b>Call Forwarding:</b> One Buzzer, Many Phones.
                            </h2>
                            
                            <p className="lead pt20 pb20">
                                <p>
                                    When someone dials your buzzer, the call is instantly forwarded to all the numbers you've set up in Buzzly. The first person to pick up is connected through to the buzzer.
                                    {/* Ring everyone that lives in your home at the same time when someone dials your buzzer. The first to answer is connected. */}
                                </p>
                                <p>
                                    Out of town and don't want to be bothered? Change who gets a call quickly and easily, whenever you like.
                                </p>
                                
                            </p>
                            
                        </div>
                        <div className="col-lg-6 mr-auto mb30 ml-3">
                            <img src="images/CallForwarding.png" alt="Call Forwarding" className="img-fluid"/>
                        </div>
                    </div>
                </div>
                <div className="container pt90 pb60" id="scheduled-access">
                    <div className="row align-items-center" >
                        <div className="col-lg-6 mr-auto mb30 ml-3 d-lg-block d-xl-block d-xs-none d-sm-none d-md-none d-none">
                            <img src="images/SchedulePeriods.png" alt="Schedule Period" className="img-fluid"/>
                        </div>
                        <div className="col-lg-5 ml-auto mb30" data-wow-delay=".1s">
                            <h2 className="title-font">
                                <b>Auto Answer:</b> Buzzly, handle my calls!
                            </h2>
                            
                            <p className="lead pt20 pb20">
                                <p>
                                    Pay attention to your party guests and not your phone or streamline home-sharing check-in. Set a Scheduled Access Period and Buzzly will answer the buzzer and open the door for you. 
                                </p>
                                <p>
                                    Set Scheduled Access Periods in advance and change them at any time.
                                </p> 
                                <p>
                                    <a href="https://help.buzzly.co/frequently-asked-questions/feature-details/how-do-scheduled-access-periods-work" target="_new"><button className="btn btn-warning btn-sm">How Scheduled Access Works</button></a>
                                </p>
                            </p>
                            
                        </div>
                        <div className="col-lg-6 mr-auto mb30 ml-auto d-inline d-lg-none d-xl-none text-center">
                            <img src="images/SchedulePeriods.png" alt="Schedule Period" className="img-fluid"/>
                        </div>
                        
                    </div>
                </div>
                <div className="container pt90 pb60">
                    <div className="row align-items-center"  id="virtual-fobs">
                        <div className="col-lg-5 ml-auto mb30" data-wow-delay=".1s">
                            <h2 className="title-font">
                            <b>Virtual Fobs:</b> Let your guests let themselves in.
                            </h2>
                            
                            <p className="lead pt20 pb20">
                                <p>
                                    Send a Buzzly Virtual Fob to a trusted person's mobile device so they can have access when you're not around. Virtual Fobs can be restricted to specific days, times, and dates.
                                </p>
                                <p>
                                    A Virtual Fob is a special link opened on a mobile device. Your guest activates the fob with one tap on their screen, enters your buzzer code on the buzzer like normal, and Buzzly will open the door.
                                </p>
                                <p>
                                    <a href="https://help.buzzly.co/frequently-asked-questions/feature-details/what-exactly-is-a-virtual-fob-and-how-does-it-work" target="_new"><button className="btn btn-warning btn-sm">How Virtual Fobs Work</button></a>
                                </p>
                            </p>
                            
                        </div>
                        <div className="col-lg-6 mr-auto mb30 ml-3">
                            {/* <img src={this.state.fobImage?"images/VirtualFob.png":"images/HeaderPhone.png"} alt="Virtual Fob" className="img-fluid"/> */}
                            <SlideShow/>
                        </div>
                    </div>
                </div>

                <div className="container pt90 pb60" id="buzz-log">
                    <div className="row align-items-center">
                        <div className="col-lg-6 mr-auto mb30 ml-3 d-lg-block d-xl-block d-xs-none d-sm-none d-md-none d-none">    
                            <img src="images/BuzzLog.png" alt="Schedule Period" className="img-fluid"/>
                        </div>
                        <div className="col-lg-5 ml-auto mb30" data-wow-delay=".1s">
                            <h2 className="title-font">
                                <b>Buzz History:</b> Keep your finger on the buzz.
                            </h2>
                            
                            <p className="lead pt20 pb20">
                                
                            
                                <p>
                                    Your Buzz History shows who answered each buzzer call, when a Scheduled Access Period answered for you, and each time the door was opened with a Virtual Fob.
                                </p>
                            </p>
                            
                        </div>
                        <div className="col-lg-6 mr-auto mb30 ml-auto d-inline d-lg-none d-xl-none text-center">
                        <img src="images/BuzzLog.png" alt="Schedule Period" className="img-fluid"/>
                    </div>
                        
                    </div>
                </div>

            </div>              
           
               
            <div id="howitworks">
                    <div className="pt90 bg-faded">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 ml-auto mr-auto mb60" data-wow-delay=".1s">
                                    <h2 className="title-font mb5 text-center">How it Works</h2>
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 text-center mb30" data-wow-delay=".1s">
                                    <i className="fal fa-user-plus text-warning fa-3x mb30"></i>
                                    <h4><b>Step 1:</b> Sign Up</h4>
                                    <p>
                                        Sign up and create your first Buzzly Number. We'll give you a special phone number in your local calling area that connects to the Buzzly system.
                                    </p>
                                </div>
                                <div className="col-lg-4 text-center mb30" data-wow-delay=".2s">
                                    <i className="fal fa-comment-alt-edit text-warning fa-3x mb30"></i>
                                    <h4><b>Step 2:</b> Update your Buzzer</h4>
                                    <p>
                                        Ask your building or property manager to update your phone number in the buzzer to your new personal Buzzly Number. Once connected, your building's buzzer will call Buzzly.
                                    </p>
                                </div>
                                <div className="col-lg-4 text-center mb30" data-wow-delay=".3s">
                                    <i className="fal fa-grin-alt text-warning fa-3x mb30"></i>
                                    <h4><b>Step 3:</b> Set Your Preferences & Relax!</h4>
                                    <p>
                                        Use your Buzzly Dashboard to set up or change Forwarding Numbers, Virtual Fobs, or Scheduled Access periods. Great work, your buzzer is now smarter!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            
            <div className="pt90 pb60 bg-white" id="pricing" onMouseOver={()=>{this.ph();}} onClick={()=>{this.pc();}}>
                <div className="container">
                    <h2 className="title-font mb5 text-center text-dark">Pricing</h2>
                    <div className="text-center text-primary mb50"><i className="fas fa-handshake font700 mr-2 fs-1x"></i><b>Try Buzzly risk free.</b> If you don't love it, just let us know in the first 14 days and we'll refund your money.</div>
                    
                    <div className="row mb20">
                        <div className="pricing-table  align-items-center text-center">
                            
                            <div className="col-lg-4 col-md-6 plan bg-white">
                                <div className="plan-header">
                                    <h2 className="text-primary"><b>Buzzly Lite</b></h2>
                                    <h3 className="text-buzzly-blue">$3.50 <small>/ month (billed yearly)</small></h3>
                                    <h4 className="text-buzzly-blue">$4 <small>/ month (billed monthly)</small></h4>
                                </div>
                            
                                <ul className="list-unstyled">
                                    <li>Local Phone Number</li>
                                    <li>Buzzer Call Forwarding to 3 Phones</li>
                                    <li>-</li>
                                    <li>-</li>
                                    <li><b>Buzztime:</b> 50 units/month</li>
                                    <li>Rollover Buzztime <small>(Annual Plans)</small></li>
                                   
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 plan bg-white popular-plan">
                                <div className="plan-header">
                                    
                                    <span class="badge badge-warning h6 text-white">MOST POPULAR</span>
                                    <h2 className="text-primary"><b>Buzzly Home</b></h2>
                                    
                                    <h3 className="text-buzzly-blue">$6 <small>/ month (billed yearly)</small></h3>
                                    <h4 className="text-buzzly-blue">$8 <small>/ month (billed monthly)</small></h4>
                                </div>
                            
                                <ul className="list-unstyled">
                                    <li>Local Phone Number</li>
                                    <li>Buzzer Call Forwarding to 5 Phones</li>
                                    <li>Unlimited Virtual Fobs</li>
                                    <li>Scheduled Access (Auto Answer)</li>
                                    <li><b>Buzztime:</b> 100 units/month</li>
                                    <li>Rollover Buzztime <small>(Annual Plans)</small></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 plan bg-white">
                                <div className="plan-header">
                                    <h2 className="text-primary"><b>Buzzly Social</b></h2>
                                    <h3 className="text-buzzly-blue">$9 <small>/ month (billed yearly)</small></h3>
                                    <h4 className="text-buzzly-blue">$11 <small>/ month (billed monthly)</small></h4>
                                </div>
                                
                                <ul className="list-unstyled">
                                    <li>Local Phone Number</li>
                                    <li>Buzzer Call Forwarding to 10 Phones</li>
                                    <li>Unlimited Virtual Fobs</li>
                                    <li>Scheduled Access (Auto Answer)</li>
                                    <li><b>Buzztime:</b> 200 units/month</li>
                                    <li>Rollover Buzztime <small>(Annual Plans Only)</small></li>

                                    
                                </ul>
                            </div>
                            
                        </div>
                    
                        
                        
                    </div>
                    <div className="row">
                            <div className="col-lg-6 mb20 text-center">
                                <p ><h4 ><i className="fas fa-stopwatch font700 mr-2 text-primary"></i>What is Buzztime?</h4></p>
                                <p>
                                    One unit of Buzztime is one Scheduled Access Period trigger, one Virtual Fob use, or up to one minute of connected call time.
                                    <span className="small"><br/>Very few of our customers need extra, but in the unlikely event that you do, overage is just 5¢/unit.
                                    </span>
                                </p>
                            
                            </div>
                            
                            <div className="col-lg-6 text-center">
                                <p ><h4 ><i className="fas fa-handshake font700 mr-2 text-primary"></i>Our Satisfaction Promise</h4></p>
                                <p>
                                    We think you'll love Buzzly, but in case you don't for whatever reason, the first Buzzly Number you create is eligible for our 14-Day Satisfaction Promise. Just let us know within the first 14 days and we'll refund your money.
                                    
                                </p>
                            
                            </div>

                        </div>
                </div>
            </div>
            <div className="pt90 pb60 bg-faded" id="faq">
                <div class="container">
                    <h2 className="title-font mb50 text-center">Frequently Asked Questions</h2>
                    <div className="text-center text-primary mb50"><a href="https://help.buzzly.co/frequently-asked-questions" target="_blank"><button className="btn btn-sm btn-warning"><b>Want more info?</b> View Detailed FAQ</button></a></div>

                    <div class="row">
                        <div class="col-lg-4 mb40">
                            <h5 class="mb10">How does Buzzly work?</h5>
                            <p>
                                When you sign up you will get your own personal Buzzly Number. You'll give this number to your building manager and ask them to update the phone number on your buzzer. From then on, all buzzer calls will be sent through Buzzly. Easy, right?
                            </p>
                        </div>
                        <div class="col-lg-4 mb40">
                            <h5 class="mb10">What is a Buzzly Number? Do I need one?</h5>
                            <p>
                                A Buzzly Number is a special, local telephone number and is needed to connect your buzzer to the Buzzly system. Your number is unique to you and not shared with anyone else. This number is only used by your buzzer; set it and forget it. 
                            </p>
                        </div>

                        
                        <div class="col-lg-4 mb40">
                            <h5 class="mb10">Will Buzzly work with my buzzer?</h5>
                            <p>
                                Chances are good that it will! Buzzly will work with any buzzer that normally connects to a landline or cellular phone. 
                            </p>
                        </div>
                        <div class="col-lg-4 mb40">
                            <h5 class="mb10">Where is Buzzly available?</h5>
                            <p>
                                Buzzly is available in Canada and the United States, except the Yukon Territory and Alaska (sorry).
                            </p>
                        </div>
                        <div class="col-lg-4 mb40">
                            <h5 class="mb10">Do I have to install anything on my buzzer?</h5>
                            <p>
                                Absolutely not! Buzzly is cloud based and requires no hardware, hammers, drills, wires, or mistakes.
                            </p>
                        </div>
                        <div class="col-lg-4 mb40">
                            <h5 class="mb10">Does Buzzly have a minimum contract?</h5>
                            <p>
                                Nope. You can choose to subscribe to a monthly or annual plan. If you don't want to renew after the month or year you purchased is up, we'll miss you, but no problem.
                            </p>
                        </div>
                        <div class="col-lg-4 mb40">
                            <h5 class="mb10">Will anyone know I'm using Buzzly?</h5>
                            <p>
                                Not unless you want to brag about how smart your buzzer is. But otherwise, no - setup is as simple as changing the buzzer phone number on file with your building manager, and your buzzer will appear to behave normally to your guests. After all, nobody wants to be greeted by a robot.
                            </p>
                        </div>
                        <div class="col-lg-4 mb40">
                            <h5 class="mb10">Is Buzzly secure?</h5>
                            <p>
                                Sure is! We use the same encryption as your bank to ensure the use of the Buzzly Dashboard is secure. Buzzly also logs every buzz that comes through and will email you any time a Scheduled Access Period or Virtual Fob is used to gain access. You can also set Buzzly to reject any incoming call that doesn't come from your building's buzzer.
                            </p>
                        </div>
                        <div class="col-lg-4 mb40">
                            <h5 class="mb10">Why is Buzztime a thing?</h5>
                            <p>
                                Having limits on buzztime ensures that Buzzly is used for apartment and condo buzzers and not as a call forwarding service. That being said, almost nobody goes over their allotment. If you're concerned, <span className="link-text" onClick={()=>{window.Intercom('showNewMessage');}}>give us a buzz</span> and we'll ensure we find the right fit for you.
                            </p>
                        </div>
                       
                        <div class="col-lg-4 mb40">
                            <h5 class="mb10">I have some more questions...</h5>
                            <p>
                                Please see our <a href="https://help.buzzly.co/frequently-asked-questions" target="_blank">detailed FAQ section</a> or <span className="link-text" onClick={()=>{window.Intercom('showNewMessage');}}>give us a buzz</span>. We're happy to receive any question, comment, or cute dog gif you want to send us.
                            </p>
                        </div>
                        
                        
                    
                    
                    
                    
                        
                    </div>
                </div>
            </div>
            
            
            <Footer></Footer>
        
            {/* <a href="#" className="back-to-top" id="back-to-top"><i className="icon-chevron-up"></i></a> */}
            
        
    </body>
        );
    }
}

export default Landing;