import React, { Component } from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import './App.css';
import Landing from './components/Landing';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Coupon from './components/Coupon';
import BrowserSupport from './components/BrowserSupport';
import ReactGA from 'react-ga';
// import LogRocket from 'logrocket';
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GTM-P7LDLQ2'
}
TagManager.initialize(tagManagerArgs)
// LogRocket.init('i06qag/buzzly');




//Google Analytics
ReactGA.initialize('UA-128724160-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {

  componentDidMount(){
    window.Intercom("boot", {
      app_id: "iut346aj"
    });
  }

  componentWillReceiveProps(newProps){
    if (this.props !== newProps){
        window.Intercom("update");
    }
}
  
  render() {
    return (
      <React.Fragment>
         <div id="preloader">
            <div id="preloader-inner"></div>
         </div>
        <Switch>
          <Route exact path ="/" component={Landing}/>
          <Route exact path ="/terms" component={Terms}/>
          <Route exact path ="/privacy" component={Privacy}/>
          <Route exact path ="/browsersupport" component={BrowserSupport}/>
          <Route exact path="/c/:coupon" render={(props)=>{return <Coupon {...this.props} {...props}/>}}/>
          <Route exact path="/r/:coupon" render={(props)=>{return <Coupon {...this.props} {...props}/>}}/>
        </Switch>
      </React.Fragment>
      
    );
  }
}



export default withRouter(App);
