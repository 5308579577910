import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Footer from './Footer';


class BrowserSupport extends Component {
   
    componentDidMount(){
        window.scrollTo(0, 0);
        document.title=`Buzzly | Browser Support`; 
    }
    render() {
        return (
        <body>
           
            <nav className="navbar navbar-expand-lg navbar-light bg-faded navbar-sticky navbar-transparent">
                <div className="container">
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarlanding" aria-controls="navbarlanding" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" id="toggler"></span>
                    </button>
                    <Link className="navbar-brand" to="/">
                        <img src="https://api.buzzly.co/logo/" alt="Buzzly" width="125" id="logo"/>
                    </Link>

                    <div className="collapse navbar-collapse" id="navbarlanding">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to="/" className="nav-link">Home</Link>
                            </li>
                           
                        
                        </ul>
                            
                    </div>
                </div>
            </nav>
            
            

             <div className="pt90 pb60">
                <div class="container">
                

                    <div class="row">
                        <div class="col-lg-12 mb40">
                          <h1>Unsupported Browser</h1>
                          <p>We're sorry, you've tried to access the Buzzly Dashboard from an unsupported browser.</p>
                          <p>Buzzly is designed to work in Google Chrome, Mozilla Firefox, Apple Safari, or Microsoft Edge.</p>
                          <p>If you have any questions, please give us a buzz at help@buzzly.co</p>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            
            
          
            
            <Footer></Footer>
        
            <a href="#" className="back-to-top" id="back-to-top"><i className="icon-chevron-up"></i></a>
            
        
    </body>
        );
    }
}

export default BrowserSupport;