import React, { Component } from 'react';

class PolicyText extends Component {
    
    render() {

        if (this.props.text === "terms"){
            return (
                <React.Fragment>
                    <h1>Terms and Conditions</h1>
                    <p><strong>YOU MUST AGREE TO THE FOLLOWING TERMS AND CONDITIONS (“TERMS”) BEFORE ACCESSING ANY CONTENT ON THIS WEBSITE OR USING ANY SERVICES  PROVIDED BY BUZZLY INC (“BUZZLY”, “US”, “OUR”, “WE”). CAREFULLY READ ALL OF THE FOLLOWING TERMS AND CONDITIONS BEFORE PROCEEDING. BY CHECKING A BOX ON A REGISTRATION OR SUBSCRIPTION SCREEN, YOU ARE ACCEPTING THESE TERMS AND ENTERING INTO A BINDING CONTRACT WITH BUZZLY. IF YOU DO NOT AGREE WITH THESE TERMS, PLEASE EXIT NOW. THESE TERMS OF USE AND DISCLAIMERS MAY BE UPDATED BY US FROM TIME TO TIME.</strong></p>

                    <h2>1. Scope</h2>
                    <p>You can review the most current version of the Terms at any time at: <a href="https://buzzly.co/terms">https://buzzly.co/terms</a>. In addition, you will be subject to any posted policies, guidelines or rules which may be posted from time to time. All such guidelines or rules are hereby incorporated by reference into these Terms.</p>

                    <h2>2. Third Party Payment Processor</h2>
                    <p>In order to use Buzzly, you will need to input certain personal and sensitive data. This data and certain other information about you is subject to Our Privacy Policy, which can be found at: <a href="https://buzzly.co/terms">https://buzzly.co/privacy.</a> </p>
                    <p>In addition to Buzzly’s proprietary services and products, We use the following service provider for the facilitation of payment:</p>

                    <p>STRIPE, INC. 185 BERRY STREET, SUITE 550 SAN FRANCISCO, CA 94107</p>

                    <p>You represent that you have first reviewed and accepted the terms and conditions governing the use of this third-party payment processor prior to using any Buzzly services or products. The terms and conditions of our third-party payment processor may be found at: <a href="https://stripe.com/terms" target="_blank" rel="noopener noreferrer">https://stripe.com/terms</a>.</p>

                    <h2>3. Payment</h2>

                    <p>You are responsible for maintaining accurate and valid payment information at all times. If any payment fails, the third-party payment processor listed above may attempt to charge your card again. In accordance with the termination provision herein, your services will be immediately suspended upon the first failed payment. Overdue services will be cancelled if payments fail for more than 9 days.Suspended services will be restored upon a successful payment.</p>

                    <p>You agree to pay all charges listed on the invoice provided to you by Buzzly. These charges may include, without limitation, service charges, overage charges, and HST and GST. </p>

                    <p>You authorize Buzzly to charge the credit card provided in your payment information for all service and overage charges, of which some may be recurring. Service charges are billed in advance for the selected term (monthly or annually) upon the commencement of the Services. For monthly plans, overage charges, if applicable, are billed on the next invoice that becomes due. For annual plans, overage charges will be billed on the next invoice that becomes due or once your balance reaches $5 (five dollars).</p>

                    <h2>4. Refund</h2>
                    <p>All charges, including without limitation, service charges, overage charges, HST,  and GST are non-refundable except where covered by our “14-Day Satisfaction Promise” Policy.</p>

                    <h2>5. “14-Day Satisfaction Promise” Policy</h2>
                    <p>First-time customers are eligible for Our 14-Day Satisfaction Promise for the first Buzzly number created. If during the first fourteen (14) days of service, you are unsatisfied with your services, contact Us via email at help@buzzly.co and We will refund service charges paid, including applicable taxes. Overage charges are not refundable under this policy. We reserve the right, at Our sole discretion, to withhold from any refund, usage charges that exceed a reasonable 14-day prorated amount. </p>

                    <h2>6. User Account and Security </h2>
                    <p>You will create a password and user account through registration with Buzzly services. You are responsible for maintaining the confidentiality of the password and account, and any unauthorized use. You agree to notify Buzzly of any unauthorized use of your password or account or any other breach of security immediately upon your discovery of such an unauthorized use or breach.</p>

                    <h2>7. Services and Content</h2>
                    <p>Subject to change from time to time and without your consent, Buzzly offers the following services through its website:</p>
                    <p>A cloud service platform that enables customers to enhance the features of their existing telephone buzzer system.

                    Any and all information, product names, company names, domain names, trademarks, patents, copyrights, or other proprietary rights (whether or not registered), web pages, graphics, data, databases, files, multimedia, software or content as used in or accessed through this website, including the manner in which the information is presented or appears and all information relating thereto (the “Content”), are the property of Buzzly or the respective owners as indicated.</p>
                    <p>Neither you nor Buzzly owns the telephone numbers provided for your use of Buzzly services. If your Buzzly services are disabled or cancelled, you may lose access to any telephone number provided to you by Buzzly. Buzzly may not be able to retrieve this number to resume its use. This number may not be used for any purpose other than for Buzzly services and in accordance with these Terms or any other conditions that Buzzly may specify on this website.</p>

                    <h2>8. Capacity</h2>
                    <p>In consideration of your use of this website and its services and content, you represent that you are of legal age to form a binding contract and are not a person barred from receiving services under the laws of Canada or other applicable jurisdictions. If you are below the legal age required to form a binding contract, you represent that you have received parental consent to use this website and its services and content.</p>

                    <h2>9. Third Party Beneficiaries</h2>
                    <p>You shall ensure that any third party user or beneficiary has read and agreed to these Terms prior to any such use of Buzzly services or this website.</p>

                    <h2>10. Your Responsibilities</h2>
                    <p>You are solely responsible for the use of the services, content, transactions, and products available on or through this website.  While every effort is made to ensure continuous availability of Buzzly services, We do not guarantee that Buzzly services are that which you require or had anticipated. Further, Our services are provided on an ‘as is’ and ‘as available’ basis. We do not guarantee uninterrupted service and you should use personal judgment when relying on Buzzly services.</p>
                    <h2>11. Limitation of Our Liabilities</h2>
                    <p>BUZZLY IS NOT TO BE USED IN AN EMERGENCY SITUATION OR TO PROVIDE ACCESS TO EMERGENCY PERSONNEL.</p>
                    <p>YOU ARE USING BUZZLY SERVICES AT YOUR OWN RISK. YOU AGREE THAT YOU ARE RESPONSIBLE FOR ANY AND ALL USE OF BUZZLY SERVICES.</p>
                    <p>BUZZLY GIVES NO REPRESENTATIONS, WARRANTIES, OR GUARANTEES, EXPRESS OR IMPLIED, AS TO THE USE OF THIS WEBSITE OR THE SERVICES, CONTENT, TRANSACTIONS, OR PRODUCTS PROVIDED THEREIN.</p>
                    <p>YOU AGREE TO INDEMNIFY AND HOLD BUZZLY HARMLESS FOR ANY AND ALL CLAIMS, WHETHER DIRECT OR INDIRECT, RESULTING FROM THE USE (OR MISUSE) OF THE BUZZLY WEBSITE OR ITS CONTENT, PRODUCTS OR SERVICES, OR OF ANY OTHER WEBSITE ACCESSED TO OR FROM THIS WEBSITE. THIS INDEMNITY SHALL ENURE TO THE BENEFIT OF OUR CORPORATE SUCCESSORS AND ASSIGNS.</p>
                    <p>IN NO EVENT SHALL BUZZLY BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND OR ANY DAMAGES WHATSOEVER, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE. (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM: 1. RELIANCE ON THE MATERIALS PRESENTED, 2. COSTS OF REPLACEMENT GOODS, 3. LOSS OF USE, DATA, OR PROFITS, 4. DELAYS OR BUSINESS INTERRUPTIONS, 5. ANY THEORY OF LIABILITY.)
                    </p>
                    <h2>12. Intellectual Property</h2>
                    <p>Buzzly software and services are patent pending and protected by intellectual property laws. The services and Content of this website may not be reproduced, published, copied, merged, modified, recompiled, licensed, distributed, sold, stored in an electronic retrieval system, or reverse engineered in any form or by any means whatsoever.</p>
                    <p>If you choose to provide feedback or suggestions to Buzzly, said feedback shall be deemed to be non-confidential and We reserve the right to freely use such information.</p>

                    <h2>13. Choice of Governing Laws</h2>
                    <p>This website is physically located on servers in Toronto, Ontario, Canada and the laws of the province of Ontario and the federal laws of Canada shall apply despite any conflict of law.</p>

                    <h2>14. Arbitration     </h2>
                    <p>You agree to pursue any claim, dispute, or controversy against Buzzly through confidential and binding arbitration conducted by a single arbitrator appointed through the agreement of the parties and subject to the provisions of the International Commercial Arbitration Act, 2017, S.O. 2017, c. 2, Sched. 5. Arbitration shall be held in Toronto, Ontario, Canada. The arbitration procedure may be amended by mutual consent of the parties, in writing, or by the arbitrator in the case of absence of agreement. The language of the arbitration shall be English.</p>

                    <h2>15. Entire Agreement</h2>
                    <p>These legal terms of use and disclaimers and any posted policies or guidelines constitute the entire agreement between you and Buzzly with respect to the use of this website and the Content. These legal terms of use and disclaimers may not be amended and no waiver of any provision of these legal terms and disclaimers shall be binding on Buzzly<strong> </strong>unless executed by Buzzly in writing. Buzzly reserves the right to modify these terms of use and disclaimers at any time without your consent.</p>

                    <h2>16. No Waiver</h2>
                    <p>The failure of Buzzly to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.</p>

                    <h2>17. Termination & Cancellation</h2>
                    <p>You agree that Buzzly may suspend or terminate your services, account, and any associated telephone number, without notice or your consent. Cause for such termination includes, without limitation: (a) breaches or violations of these Terms or other incorporated policies, guidelines, or agreements; (b) requests by law enforcement or other government administrative bodies; (c) your own request; and (d) unexpected technical or security issues. </p>
                    <p>Services are offered on a monthly or annual pre-purchase basis and will renew automatically until cancelled. You may terminate automatic renewal of your services at any time in accordance with the terms and policies posted on this website. Your services will be maintained for the duration of any pre-purchased term that is already in effect. If such a termination occurs, you agree to pay any outstanding fees as indicated by your Buzzly invoice at the time of termination. Upon request, your services may be terminated immediately, without refund of any remaining term of pre-purchased services by contacting Us directly. </p>
                    <p>You may terminate your entire account upon request, without refund of any remaining term of pre-purchased services by contacting Us directly. </p>

                    <p>© <strong>Buzzly Inc,</strong> {new Date().getFullYear()}, TORONTO, CANADA.</p>



                </React.Fragment>
            
            );

        } else if (this.props.text ==="privacy"){
            return (
                <React.Fragment>
       

                    <h1>Privacy Policy</h1>

                    <p>Effective date: November 1, 2018</p>


                    <p>Buzzly Inc ("us", "we", or "our") operates the buzzly.co website (the "Service").</p>

                    <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. </p>

                    <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from <a href="https://buzzly.co/terms">https://buzzly.co/terms</a></p>
                    <h2>Information Collection And Use</h2>

                    <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
                    <h3>Types of Data Collected</h3>
                    <h4>Personal Data</h4>
                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
                    <ul><li>Email Address</li>
                    <li>First and last name</li>
                    <li>Phone number(s)</li>
                    <li>Address, State, Province, Zip/Postal Code, City</li>
                    <li>Cookies & Usage Data</li></ul>


                    <h4>Usage Data</h4>

                    <p>We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

                    <h4>Cookies </h4>
                    <p>We use cookies and similar technologies on our Service in order to hold certain information.
                    </p>
                    <p>Cookies are files with small amount of data which are sent to your browser from a website and stored on your device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you will not be able to use the Service.
                    </p>
                    <h2>Use of Data    </h2>
                    <p>Buzzly Inc. uses the collected data for various purposes:</p>
                    <ul><li>To provide and maintain the Service</li>
                    <li>To notify you about matters relating to the Service</li>
                    <li>To allow you to participate in interactive features of our Service</li>
                    <li>To provide customer support</li>
                    <li>To provide analysis or valuable information so that we can improve the Service</li>
                    <li>To monitor the usage of the Service</li></ul>

                    <h2>Transfer Of Data</h2>
                    <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction. If you are located outside Canada and choose to provide information to us, please note that we transfer the data, including some Personal Data, to Canada and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                    </p>
                    <p>Buzzly Inc will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
                    </p>
                    <h2>Disclosure Of Data</h2>
                    <h3>Legal Requirements</h3>
                    <p>Buzzly Inc may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                    <ul><li>To comply with a legal obligation</li>
                    <li>To protect and defend the rights or property of Buzzly Inc.</li>
                    <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>To protect the personal safety of users of the Service or the public</li>
                    <li>To protect against legal liability
                    </li></ul>
                    <h3>Security Of Data</h3>
                    <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. Your data is stored behind secured networks only accessible to a limited number of individuals bound by confidentiality requirements. All sensitive information you provide is encrypted via Secure Socket Layer (SSL) technology. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                    <h3>Service Providers</h3>
                    <p>We do and may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services including but not limited to payment processing, or to assist us in analyzing how our Service is used. These third parties may have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                    <p><strong>Stripe</strong></p>
                    <p>All of our payment processing is handled by Stripe. Your payment information will be transmitted directly to Stripe for this purpose. We do not process or save credit card information on our servers. You must also read Stripe’s privacy policy located at <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">https://stripe.com/privacy</a>. </p>

                    <h3>Analytics</h3>
                    <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

                    <p><strong>Google Analytics</strong></p>

                    <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data may be shared with other Google services. For example, Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>

                    <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents Google Analytics code from sharing information with Google Analytics about visits activity.</p>

                    <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page.</p>

                    <h2>Links To Other Sites</h2>
                    <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.

                    We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

                    <h2>Children's Privacy</h2>
                    <p>Our Service does not address anyone under the age of 18 ("Children").</p>
                    <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data without your consent, please contact us. If we become aware that we have collected Personal Data from children without parental consent, we take steps to remove that information from our servers.</p>

                    <h2>Changes To This Privacy Policy</h2>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, please contact us by email at help@buzzly.co.</p>


                </React.Fragment>
            
            );

        }
        
    }
}

export default PolicyText;