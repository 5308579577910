import { Component } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


class Refer extends Component {

    componentDidMount(){

        if (this.props.match.params.coupon){
           
            cookies.set("coupon",String(this.props.match.params.coupon),{
                path: "/",
                maxAge: 60*60*24,
                domain: ".buzzly.co",
                secure: true
            })
                this.props.history.push("/");
    
       
            
           
           
        } else {
            this.props.history.push("/");
        }
        
    }
    render(){
        return(null);
    }
    
}

export default Refer;