import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <footer className="footer pt90 pb60 bg-buzzly-blue">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-8 mr-auto ml-auto mb30">
                            <a href="https://cloud.buzzly.co/register" className="btn btn-sm btn-outline-warning mr-3 mb-2" >Sign Up</a>
                            <a href="https://cloud.buzzly.co/login" className="btn btn-sm btn-white-outline mr-3 mb-2">Log In</a>
                            <h4 className="text-white mt-2"><b>Questions?</b> <span className="link-text" onClick={()=>{window.Intercom('showNewMessage');}}>Give us a buzz.</span></h4>
                            <ul className="list-unstyled text-white">
                            
                                <li><a href="https://help.buzzly.co">Support</a> | <Link to="/terms">Terms & Conditions</Link> | <Link to="/privacy">Privacy Policy</Link></li>
                                <li></li>
                                <li></li>
                        
                            </ul>
                        </div>
                        <div className="col-lg-4 ml-auto mr-auto mb30 text-white">
                                
                                <p><img src="https://api.buzzly.co/logo/white/white" alt="Buzzly Logo" height="35"/></p>
                                <p>
                                    Made with <i className="fas fa-heart"></i> in Toronto.
                                    <br/>
                                    &copy; {new Date().getFullYear()} Buzzly Inc. Patent Pending.
                                </p>
                            
                    
                            
                        </div>
                        
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;