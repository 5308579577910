import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Footer from './Footer';
import PolicyText from './PolicyText';

class Terms extends Component {
   
    componentDidMount(){
        window.scrollTo(0, 0); 
        document.title=`Buzzly | Terms & Conditions`; 
    }

 
    render() {
        return (
        <body >
           
            <nav className="navbar navbar-expand-lg navbar-light bg-faded navbar-sticky navbar-transparent">
                <div className="container">
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarlanding" aria-controls="navbarlanding" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" id="toggler"></span>
                    </button>
                    <Link className="navbar-brand" to="/">
                        <img src="https://api.buzzly.co/logo/" alt="Buzzly" width="125" id="logo"/>
                    </Link>
                    

                    <div className="collapse navbar-collapse" id="navbarlanding">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to="/" className="nav-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href="#terms">Terms & Conditions</a>
                            </li>
                            <li className="nav-item">
                            <Link to="/privacy" className="nav-link">Privacy Policy</Link>
                            </li>
                            
                            {/* <li className="nav-item">
                                <a className="nav-link" href="https://help.buzzly.co">Support</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-warning text-nowrap" id="login-nav" href="https://cloud.buzzly.co/login">Log In</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link btn btn-warning ml-0" href="https://cloud.buzzly.co/register"><span className="text-buzzly-blue">Sign Up</span></a>
                            </li> */}
                        
                        </ul>
                            
                    </div>
                </div>
            </nav>
            
            

             <div className="pt90 pb60" id="terms">
                <div class="container">
                   

                    <div class="row">
                        <div class="col-lg-12 mb40">
                            <PolicyText text="terms"></PolicyText>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            
            
          
            
            <Footer></Footer>
        
            {/* <a href="#" className="back-to-top" id="back-to-top"><i className="icon-chevron-up"></i></a> */}
            
        
    </body>
        );
    }
}

export default Terms;